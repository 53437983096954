import React from "react";
const createReactClass = require('create-react-class');

const IconXcode = createReactClass({
  render() {
    return (
      <svg className="express" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" aria-labelledby="title">
        <title id="title">Xcode Icon</title>
        <path d="M12.435 14.349c-.182.518-.378 1.07-.602 1.702l-.821 2.313c-.336.95-.604 1.746-.803 2.388a.798.798 0 0 1-.625.55l-6.812 1.189L0 6.933l14.676-2.56a.532.532 0 0 1 .622.488c.009.127.01.243.007.347l-.007.174c-.063.542-.527 2.109-1.306 4.448a3053.345 3053.345 0 0 1-2.632-2.97c-.291-.33-.623-.434-.907-.39l-.031.005c-.57.111-.93.825-.334 1.494a2367.03 2367.03 0 0 1 3.283 3.696l-.301.871l-3.007.525l-.676 1.82l3.048-.532zM7.87 17.586l2.476-6.629l.296-.795a.843.843 0 0 0-.505-1.081a.855.855 0 0 0-1.093.501l-.295.792l-2.47 6.635zM4.109 14.1l.293 1.65l1.8-.314l.807-1.842zm1.83 6.23l1.812-2.428l-1.595-.583zm12.13-5.937a87.109 87.109 0 0 1 .33-1.38l1.921-.336l-.294-1.65l-1.175.205c.213-.812.42-1.557.622-2.231c.324-1.085.625-1.964.896-2.605a5.85 5.85 0 0 1 .038-.087a.611.611 0 0 1 .864-.283l.136.078c.272.157.462.426.517.736L24 18.49l-6.346 1.11a.532.532 0 0 1-.611-.64c.23-1.04.431-1.943.603-2.71c.074.087.162.164.267.224c.556.32 1.229.26 1.516.65c.321.437.452.736.452.736s.372-.417.337-1.004c-.138-2.332-1.934-2.65-1.934-2.65zm1.505-9.687c.362-.238.663-.358.965-.358c.482 0 .784.299.965.478c.24.298.964.537 1.266.537c.24 0 .542-.716.723-1.313c.18-.596.241-1.312.12-1.432c-.12-.119-.964-.298-1.145-.298c-.12.06-.301.179-.723.179s-.905-.358-1.206-.656c-.482-.478-1.146-.716-1.748-.895A7.564 7.564 0 0 0 16.8.709c-.965-.06-1.93-.06-2.834.239c-.361.12-.723.238-1.085.417c-.12.06-.422.18-.482.24c-.06.059-.12.178 0 .178s.542-.06.542-.06s-.482.24-.482.358c0 .12.06.12.12.12c.061 0 .302-.06.543-.12c.242-.06.784-.238 1.327-.238c.603 0 1.266.179 1.808.775c.965 1.134.784 2.506.784 2.864c-.18 2.148-5.064 15.094-5.245 15.99c-.18.894-.18 1.431.904 1.73c1.086.298 1.568 0 1.749-.418c.24-.657 3.255-16.705 5.124-18.078z" /></svg>
    )
  }
});

export default IconXcode;
