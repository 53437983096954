import React, { Component } from 'react';
class Appetize extends Component {
  render() {
    return (
      <iframe 
        width="410px"
        height="1000px"
        src="https://appetize.io/embed/v020zmh4f8j83ptvnkcc6qpg2r?device=iphonex&scale=100&orientation=portrait&osVersion=13.3" />
    );
  }
}

export default Appetize;
